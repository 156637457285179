<div class="flex min-h-screen">
  <div class="flex-1 flex flex-col">
    <div class="flex-1 flex sm:items-center justify-center">
      <div class="max-w-sm w-full p-4">
        <h2 class="pb-2 text-gray-900 text-3xl leading-3xl font-semibold">Log in to Step Portal</h2>

        <p class="pb-8 text-gray-500">Please enter your details</p>

        <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
          <app-label [required]="true" [control]="formGroup.controls.email" title="Email" #emailLabel></app-label>
          <mat-form-field subscriptSizing="dynamic">
            <app-svg-icon name="mail-01" matPrefix></app-svg-icon>

            <input (focusin)="emailLabel.focus()" (focusout)="emailLabel.focusout()" matInput id="email" placeholder="Email" name="email" type="email" autocomplete="email" formControlName="email" class="relative z-[1]" />

            <mat-error *ngIf="formGroup.controls.email.hasError('required')">Email is required</mat-error>

            <mat-error *ngIf="formGroup.controls.email.hasError('email')">Please enter a valid email address</mat-error>
          </mat-form-field>

          <app-label [required]="true" [control]="formGroup.controls.password" title="Password" #passwordLabel></app-label>
          <mat-form-field subscriptSizing="dynamic" class="with-suffix">
            <input
              #passwordInput
              id="password"
              matInput
              placeholder="Password"
              name="password"
              autocomplete="current-password"
              type="password"
              formControlName="password"
              class="relative z-[1]"
              (focusin)="passwordLabel.focus()"
              (focusout)="passwordLabel.focusout()"
            />

            <app-password-visibility [inputElement]="passwordInput" matSuffix></app-password-visibility>

            <mat-error *ngIf="formGroup.controls.password.hasError('required')">Password is required</mat-error>
          </mat-form-field>

          <button type="button" (click)="showForgotPassword()" class="link-0 text-sm leading-sm my-6 self-start">
            Forgot password
          </button>

          <app-button
            [buttonType]="ButtonType.SUBMIT"
            [buttonStyle]="ButtonStyle.BTN_0"
            [buttonPadding]="ButtonPadding.BTN_PADDING_0"
            [fluid]="true"
          >
            Sign in
          </app-button>
        </form>

        <p class="pt-6 text-center text-gray-500 text-sm leading-sm">
          Don't have an account?
          <a routerLink="/registration" class="link-0">Sign up</a>
        </p>
      </div>
    </div>

    <app-auth-footer></app-auth-footer>
  </div>

  <img
    src="/assets/images/lake-zug-color.jpg"
    alt="Lake Zug"
    class="flex-1 hidden lg:block object-cover max-h-screen sticky top-0 overflow-hidden"
  />
</div>
