import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';
import { QuotaKey } from '../../_models/quota';
import { PortalService } from '../../_services/portal.service';
import { RequestAgentQuotaDialogData } from './request-agent-quota-dialog-data.interface';
import { AccountService } from "../../_services/account.service";
import { Organization } from "../../_models/organization";
import { RegistrationState } from "../../_models/registration-state.enum";

export interface RequestAgentQuotaDialogFormValue {
  requestedValue: number | null;
  requestedReason: string | null;
}

const QUOTA_REQUESTED_SUCCESSFULLY = 'Your request was send and will be processed in the next days';

@Component({
  selector: 'app-request-agent-quota-dialog',
  templateUrl: './request-agent-quota-dialog.component.html',
  styleUrls: ['./request-agent-quota-dialog.component.scss'],
})
export class RequestAgentQuotaDialogComponent implements OnInit {
  readonly formGroup = this.formBuilder.group({
    requestedValue: [null, [Validators.required]],
    requestedReason: [null, [Validators.required]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;
  quotaKey?: QuotaKey;
  organization?: Organization;
  readonly RegistrationState = RegistrationState;

  constructor(
    private portalService: PortalService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private matDialogRef: MatDialogRef<RequestAgentQuotaDialogComponent>,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) private dialogData: RequestAgentQuotaDialogData
  ) {}

  ngOnInit(): void {
    this.quotaKey = this.dialogData.quotaKey;

    this.loading = true;

    this.accountService.getOrganization().subscribe((organization) => {
      this.organization = organization;
      this.loading = false;
    });
  }

  onCancel(): void {
    this.matDialogRef.close(false);
  }

  onSubmit(): void {
    const { requestedValue, requestedReason } = this.formGroup.value as RequestAgentQuotaDialogFormValue;

    if (!requestedValue || !requestedReason) {
      return;
    }

    this.loading = true;

    this.portalService.requestQuota(this.dialogData.quotaKey, requestedValue, requestedReason).subscribe({
      next: () => {
        this.toastrService.success(QUOTA_REQUESTED_SUCCESSFULLY);
        this.loading = false;
        this.matDialogRef.close();
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
