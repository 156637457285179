import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AddPaymentWizardDialogComponent } from '../_dialog/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { SkeletonLoaderRounding } from '../components/skeleton-loader/skeleton-loader.component';
import { AccountService } from "../_services/account.service";
import { Organization } from "../_models/organization";
import { RegistrationState } from '../_models/registration-state.enum';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit, OnDestroy {
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;
  readonly RegistrationState = RegistrationState;

  private terminator$ = new Subject<void>();
  private refreshCardDetails$ = new Subject<void>();

  loading: boolean = false;
  initialLoad: boolean = true;
  organization: Organization;


  constructor(public router: Router, private matDialog: MatDialog,  private accountService: AccountService) {}

  ngOnInit(): void {
    this.loading = true;

    this.accountService.getOrganization().subscribe((organization) => {
      this.organization = organization;
      this.loading = false;
      this.initialLoad = false;
    });
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
    this.refreshCardDetails$.complete();
  }

  onAddPaymentMethod(): void {
    const dialogRef = this.matDialog.open(AddPaymentWizardDialogComponent, {
      // See `mat-dialog.scss`
      panelClass: 'dialog-full-screen',
    });

    dialogRef.afterClosed().subscribe((wizardCompleted) => {
      if (!wizardCompleted) {
        return;
      }
    });
  }
}
