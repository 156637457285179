import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PasswordResetDialogComponent } from "src/app/_dialog/password-reset-dialog/password-reset-dialog.component";
import {
  RequireVerificationDialogData
} from "src/app/_dialog/require-verification-dialog/require-verification-dialog-data.interface";
import { LoginNavigationState } from "src/app/_models/login-navigation-state.interface";
import { RegistrationState } from "src/app/_models/registration-state.enum";
import { SpinnerService } from "src/app/_services/spinner.service";
import { ButtonPadding, ButtonStyle, ButtonType } from "src/app/components/button/button.component";
import {
  RequireVerificationDialogComponent
} from "../../_dialog/require-verification-dialog/require-verification-dialog.component";
import { AccountService } from "../../_services/account.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    const navigationState = this.getNavigationState();

    if (navigationState) {
      this.formGroup.setValue(navigationState);
      this.onSubmit();
    }
  }

  private getNavigationState(): LoginNavigationState | undefined {
    const navigation = this.router.getCurrentNavigation();

    return navigation?.extras?.state as LoginNavigationState | undefined;
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.spinnerService.show();

    const { email, password } = this.formGroup.value;

    this.accountService.login(email, password).subscribe({
      next: ({ organization }) => {
        if (organization?.registrationState === RegistrationState.NEW) {
          this.dialog.open<RequireVerificationDialogComponent, RequireVerificationDialogData, undefined>(
            RequireVerificationDialogComponent,
            {
              data: {
                email,
                password,
              },
            }
          );
        } else {
          const goto = this.activatedRoute.snapshot.queryParamMap.get('goto');
          if (organization?.registrationState === RegistrationState.EVALUATION_ENDED || goto === 'upgrade') {
            this.router.navigate(['/upgrade']);
          } else {
            this.router.navigate(['/cluster']);
          }
        }

        this.spinnerService.hide();
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }

  showForgotPassword(): void {
    this.dialog.open(PasswordResetDialogComponent);
  }
}
