<h5 class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">Cluster</h5>

<p class="text-sm leading-sm text-gray-500 pb-5">
  You may select a unique name of your choosing.
</p>

<div class="flex items-start gap-5">
  <mat-form-field subscriptSizing="dynamic" class="flex-1">
    <input matInput placeholder="Cluster name" name="clusterName" type="text" [formControl]="nameControl" class="relative z-[1]" />

    <mat-error *ngIf="nameControl.hasError('required')">Cluster name is required</mat-error>
    <mat-error *ngIf="nameControl.hasError('pattern')">
      Cluster name can't contain special characters or spaces
    </mat-error>
  </mat-form-field>

  <ng-container *ngIf="customerPrices ? customerPrices : { controller: { amount: 0 } } as customerPrices">
    <ng-container
      *ngIf="
        (customerPrices['controller'].amount / 100) * billingPeriod.minutes
          | currency : '' : '' : billingPeriod.decimalPlaces as amount
      "
    >
      <app-costs [amount]="amount" [unit]="billingPeriod.unit"></app-costs>
    </ng-container>
  </ng-container>
</div>
