<h1 class="text-3xl leading-3xl font-medium text-gray-900 pb-6">Billing</h1>

<app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_LG">
  <p class="text-sm leading-sm text-gray-500 mb-4">Current period: {{ currentInvoice ? (currentInvoice.period_start * 1000 | date) + ' - ' + (currentInvoice.period_end * 1000 | date) : "" }}</p>
</app-skeleton-loader>

<div class="pb-6">
  <app-skeleton-loader
    [loading]="loading"
    [rounding]="SkeletonLoaderRounding.ROUNDED_LG"
    [ngClass]="{
      'block rounded-lg overflow-hidden': loading
    }"
  >
    <table mat-table [dataSource]="dataLines" matSort id="current-invoice">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let dataLine">
          <div class="text-sm leading-sm font-medium text-gray-900">
            {{ dataLine.price.nickname }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
        <td mat-cell *matCellDef="let dataLine">
          <div class="text-sm leading-sm text-gray-900">
            {{ dataLine.quantity | calcTime }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Price per minute</th>
        <td mat-cell *matCellDef="let dataLine">
          <div class="text-sm leading-sm text-gray-900 font-mono">
            {{ dataLine.price.unit_amount_decimal / 100 | currency : dataLine.currency : "" : "1.4-4" }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let dataLine">
          <div class="text-sm leading-sm text-gray-900 font-mono">
            {{ dataLine.currency | uppercase }}
            {{ dataLine.amount / 100 | currency : "" : "" }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="billingTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: billingTableColumns"></tr>
    </table>

    <ng-container *ngIf="loading && initialLoad">
      <div class="h-[4.5rem]"></div>
      <div class="h-[4.5rem]"></div>
      <div class="h-[4.5rem]"></div>
      <div class="h-[4.5rem]"></div>
      <div class="h-[4.5rem]"></div>
      <div class="h-[4.5rem]"></div>
    </ng-container>
  </app-skeleton-loader>
</div>

<div *ngIf="invoices.length" class="pb-6">
  <table mat-table [dataSource]="invoices" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
      <td mat-cell *matCellDef="let invoice">
        <div class="text-sm leading-sm font-medium text-gray-500">
          <a [routerLink]="['/billing/invoice', invoice.number]" matRipple>{{ invoice.number }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let invoice">
        <div class="text-sm leading-sm text-gray-900">
          {{ invoice.created * 1000 | date }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="paid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount paid</th>
      <td mat-cell *matCellDef="let invoice">
        <div class="text-sm leading-sm text-gray-900 font-mono">
          {{ invoice.currency | uppercase }}
          {{ invoice.amount_paid / 100 | currency : "" : "" }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
      <td mat-cell *matCellDef="let invoice">
        <div class="text-sm leading-sm text-gray-900 font-mono">
          {{ invoice.currency | uppercase }}
          {{ invoice.amount_due / 100 | currency : "" : "" }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let invoice">
        <div class="flex items-center gap-3">
          <ng-container *ngIf="invoice.invoice_pdf">
            <a
              [href]="invoice.invoice_pdf"
              matRipple
              class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full"
            >
              <app-svg-icon name="file-download-02"></app-svg-icon>
            </a>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="invoicesTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: invoicesTableColumns"></tr>
  </table>
</div>

<app-skeleton-loader
  [loading]="cardDetailsLoading"
  [rounding]="SkeletonLoaderRounding.ROUNDED_LG"
  [ngClass]="{
    'block rounded-lg overflow-hidden': cardDetailsLoading
  }"
>
  <div class="p-6 border border-gray-200 elevate-0 rounded-lg">
    <ng-container *ngIf="cardDetails">
      <h4 class="text-lg leading-lg font-medium text-gray-900 pb-6">
        Change your card here
      </h4>

      <app-card-details [cardDetails]="cardDetails" (editClick)="onEditPaymentMethod()"></app-card-details>
    </ng-container>

    <ng-container *ngIf="!cardDetails">
      <h4 class="text-lg leading-lg font-medium text-gray-900">
        Ready to upgrade?
        <button type="button" (click)="onAddPaymentMethod()" class="link-0">
          Add your payment information to unlock Step's full potential with the Flexible plan.
        </button>
      </h4>
    </ng-container>
  </div>
</app-skeleton-loader>
